import { db, storage } from "./firebaseConfig";
import firebase from "firebase/compat/app";
import api from "./cloudFunctionsApiCalls";
import * as routes from "../utils/urlRoutes";
import { createStringFromDate } from "../common/commonMethods";
import { getAuth } from "firebase/auth";
import {
  doc,
  setDoc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  documentId,
  onSnapshot,
  serverTimestamp,
  updateDoc,
  addDoc,
  deleteDoc,
  arrayUnion,
  limit,
  orderBy,
  Timestamp,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadString,
  deleteObject,
} from "firebase/storage";
import { captureScreenshot } from "../utils/takeScreenShot";

export const fetchExamList = async (examinee_id) => {
  let examIds = [];
  let paginationExamIds = [];
  let exams = [];

  const q = query(doc(db, "user_exams/" + examinee_id));
  const userExamDoc = await getDoc(q);
  const userExamData = userExamDoc.data() ?? {};
  Object.keys(userExamData).forEach((examId) => {
    examIds.push(examId);
  });

  /*
  pagination exam arrayini kullanmamızın sebebi firebase where querysini kullanırken aynı anda en fazla 10 tane çekebilmektedir.
  Biz burda bunun önüne geçip daha fazla veri çekmesini sağladık. Aşağıdaki for döngüsü bunun için.
  */
  for (let i = 0; i < examIds.length; i++) {
    paginationExamIds.push(examIds[i]);
    if (paginationExamIds.length === 10 || i === examIds.length - 1) {
      const examQuery = query(
        collection(db, "exams"),
        where(documentId(), "in", paginationExamIds)
      );
      const querySnapshot = await getDocs(examQuery);
      querySnapshot.forEach((doc) => {
        let ex = { ...doc.data(), id: doc.id };
        exams.push({
          ...ex,
          start_date: ex.start_date?.toDate(),
          end_date: ex.end_date?.toDate(),
        });
      });
      paginationExamIds = [];
    }
  }

  return exams;
};

export const fetchQuizList = async (type) => {
  let quizes = [];
  const examsQuery = query(collection(db, "exams"), where("type", "==", type));
  try {
    const querySnapshot = await getDocs(examsQuery);
    querySnapshot.forEach((doc) => {
      quizes.push({ ...doc.data(), id: doc.id });
    });
    return quizes;
  } catch (error) {
    console.log("fetchQuizList --> ", error);
  }
};

export const fetchExam = async (exam_id) => {
  const examSS = await getDoc(doc(db, "exams", exam_id)).catch((e) =>
    console.log(e)
  );
  const examData = { ...examSS.data() };
  const start_date = examData.start_date?.toDate();
  const end_date = examData.end_date?.toDate();
  return examSS.data()
    ? { ...examSS.data(), start_date, end_date, id: examSS.id }
    : { message: "sınav bulunamadı" };
};

export const fetchCreatedExamList = async (creator_id) => {
  let exams = [];
  const examsQuery = query(
    collection(db, "exams"),
    where("creator_id", "==", creator_id)
  );
  try {
    const querySnapshot = await getDocs(examsQuery);
    querySnapshot.forEach((doc) => {
      let ex = { ...doc.data(), id: doc.id };
      exams.push({
        ...ex,
        start_date: ex.start_date?.toDate(),
        end_date: ex.end_date?.toDate(),
      });
    });
    return exams;
  } catch (error) {
    console.log("fetchCreatedExamList --> ", error);
  }
};

export const createNewExam = async () => {
  const user = getAuth().currentUser;
  const creator_id = user.uid;
  const exam = {
    creator_id,
    end_date: serverTimestamp(),
    header: "Yeni Eklenen Sınav",
    start_date: serverTimestamp(),
    status: 1,
  };
  const examRef = await addDoc(collection(db, "exams"), exam);
  const exam_id = examRef.id;
  const exam_questions = { creator_id, exam_id };
  const examQuestionRef = await addDoc(
    collection(db, "exam_questions"),
    exam_questions
  );
};

export const fetchExamQuestionsId = async (exam_id) => {
  const user = getAuth().currentUser;
  let exam_questions_id = "";

  /*
    Bu başlangıcı olan ve exam question çekmen gereken her yerde farketmiş olacağın gibi 2 where kullandık. Bunun sebebi
    firebase rulsea yazmış olduğumuz condition. Şimdi firebase rules kısmına baktığında (firebsore database sekmesi içinde 
    Rules bölümünde) baktığında en aşağıda (şu an 71. satırda ilerde değişirse burayı güncelleyebiliriz) match /exam_questions/{uid}
    kısmında resource.data.creator_id== request.auth.uid gibi bir ifade göreceksin. Şimdi bu ifade bize şunu anlatıyor, sisteme 
    login olan kişi bu sınavı oluşturmuş mu diye bakıyoruz. Şimdi bu sınava kayıt oluş kişibin bilgilerinde exam questions id bilgisi
    yoksa firebase rules bunu tahmin ederek getirmye çalışıyor ve bazı durumlarda permission hatası veriyor. Bu hatanın önüne
    geçebilmek için bizde hem exam_id hemde exam question id bilgisini göndererek arama yapıyoruz. Şimdi sen diyeceksin ki bu 
    adam sınavı satın aldı ama oluşturmadı. Bunun içinde user_exam collection'ına exam_questions_id bilgisini eklememiz gerekmekte
    Bunun sebebide eğer bu id bilgisi var ise kullanıcıda direkt getDoc işlemi yaparak document'ı elde etsin. Rules kısmında da
    bunu allow get: if "is_questions_accessable ();" görebilirsin. Firebase Rules ile ilgili sorularını Yavuz Selim Bey daha net 
    cevap verecektir. 
  */

  const examQuestionsQuery = query(
    collection(db, "exam_questions"),
    where("creator_id", "==", user.uid),
    where("exam_id", "==", exam_id)
  );
  try {
    const ss = await getDocs(examQuestionsQuery);
    ss.forEach((doc) => {
      exam_questions_id = doc.id;
    });
    return exam_questions_id;
  } catch (error) {
    console.log(error);
  }
};

export const getQuestions = async (filter) => {
  const { exam_id, exam_questions_id } = filter;
  let questions = [];
  try {
    let examQuesionsDoc = null;

    if (exam_questions_id) {
      examQuesionsDoc = await getDoc(
        doc(db, "exam_questions", exam_questions_id)
      );
    } else if (exam_id) {
      const user = getAuth().currentUser;
      const examQuestionsQuery = query(
        collection(db, "exam_questions"),
        where("creator_id", "==", user.uid),
        where("exam_id", "==", exam_id)
      );
      const ss = await getDocs(examQuestionsQuery);
      ss.forEach((doc) => {
        examQuesionsDoc = doc;
      });
    } else {
      return { message: "Sınav parametreleri hatalı! Sorular çekilemedi." };
    }
    const fetcehdQuestions = examQuesionsDoc.data().questions ?? {};

    Object.keys(fetcehdQuestions).forEach((q_key) => {
      let q_obj = fetcehdQuestions[q_key];
      q_obj.id = q_key;
      questions.push(q_obj);
    });
  } catch (error) {
    console.log(error);
    return { message: "Sorular çekilemedi" };
  }

  questions.sort((q1, q2) => {
    let q1_order = q1.order ?? 0;
    let q2_order = q2.order ?? 0;
    return q1_order - q2_order;
  });

  return questions;
};

export const fetchAQuestions = async (uid) => {
  const q = await getDoc(doc(db, "questions", uid));
  return { ...q.data(), id: q.id };
};

export const getUserQuestions = async (filter) => {
  let questions = [];
  if (filter.creator_id) {
    const questionQuery = query(
      collection(db, "questions"),
      where("creator_id", "==", filter.creator_id)
    );
    const querySnapshot = await getDocs(questionQuery);
    querySnapshot.forEach((doc) => {
      questions.push({ ...doc.data(), id: doc.id });
    });
  }
  return questions;
};

export const listenExamById = (element, filter, callback) => {
  /**
   bu metot sınavın statusünün değişip değişmediğini real timeda dinleyerek ona göre sınavı anında kapatmamızı sağlıyor.
   sistemimizin en önemli parçalarından biri.
   */
  const examSS = onSnapshot(doc(db, "exams", filter.examId), async (doc) => {
    if (doc.data().status === 3) {
      await captureScreenshot(element, filter.examId);
    }
    callback({ ...doc.data(), id: doc.id });
  });
};

export const listenAnswerForm = (filter, callback) => {
  /**
   Bu metotta yukardaki metota benzer bir şekilde sınavı çözenlerin cevap formlarını dinlemekte ve yapılan değişiklikleri
   anında kaydedip ekranda göstermemizi sağlamakta. En önemli parçalardan diğeri.
   */
  const form_id = filter.userId + "_" + filter.examId;
  const unsub = onSnapshot(doc(db, "answer_forms", form_id), (doc) => {
    let data = doc.data();
    if (doc && data)
      callback({
        ...data,
        id: doc.id,
        start_at: data.start_at?.toDate(),
        start_at_string: createStringFromDate(data.start_at?.toDate()),
        end_at: data.end_at?.toDate(),
        finished_at: data.finished_at?.toDate(),
        finished_at_string: createStringFromDate(data.finished_at?.toDate()),
        last_response_updated_at_string: createStringFromDate(
          data.last_response_updated_at?.toDate()
        ),
      });
    else callback({});
  });
};

const insertLog = ({
  user_name,
  question_id,
  question_title,
  new_answer,
  user_id,
  exam_id,
  error,
}) => {
  /*
    Bu kısım çok önemli. Burada sınavı cevaplayan kişinin logunu tutuyoruz. Tozoş sınavlarında (oyun veya şampiyona da) itiraz
    sürecinde ya ben böyle yapmıştım şöyle oldu, cevap işlemedi veya cevabı kaydetmedi gibi itirazlar geldiğinde tam olarak loglara
    bakıyoruz ve itirazı değerlendiriyoruz.
  */
  if (!user_id) {
    try {
      const user = getAuth().currentUser;
      user_id = user.uid;
    } catch (error) {
      user_id = "-1";
    }
  }

  try {
    const now = Date.now();
    const log_deletion_date = new Date(now + 40 * 24 * 60 * 60 * 1000);
    const logId = exam_id + "_" + user_id;
    let newAction = {
      action_date: Timestamp.now(),
      user_name: user_name ?? "",
      question_id,
      question_title: question_title ?? "",
      new_answer: new_answer ?? "",
    };
    if (error) newAction.error = error;

    setDoc(
      doc(db, "logs", logId),
      {
        user_id,
        exam_id,
        log_deletion_date,
        actions: arrayUnion(newAction),
      },
      { merge: true }
    );
  } catch (e) {
    console.log("*** log error: ", e);
  }
};

export const deleteLog = () => {};

export const saveAnswerOfQuestion = async (params) => {
  /*
    burası bizim answer forma kullanıcının cevaplarını kaydettiğimiz yer. Ayrıca loglama işlemini de çağırıyoruz.
   */
  const { examId, questionId, questionTitle, userAnswer, userId, userName } =
    params;

  let form_id = userId + "_" + examId;
  const answer = {
    exam_id: examId,
    user_id: userId,
    last_response_updated_at: serverTimestamp(),
    [params.questionId]: {
      responder_name: userName,
      response: params.userAnswer,
      response_date: serverTimestamp(),
    },
  };

  const ref = doc(db, "answer_forms", form_id);

  try {
    await setDoc(ref, answer, { merge: true });

    insertLog({
      user_name: userName,
      question_id: questionId,
      question_title: questionTitle,
      new_answer: userAnswer,
      user_id: userId,
      exam_id: examId,
    });
    return {
      message: "Cevap " + userAnswer + " olarak güncellendi.",
      variant: "success",
    };
  } catch (error) {
    if (error.code === "permission-denied") {
      insertLog({
        user_name: userName,
        question_id: questionId,
        new_answer: userAnswer,
        user_id: userId,
        exam_id: examId,
        error:
          "Sınav erişilebilir olmadığı için cevap kaydedilemedi:" + error.code,
      });

      return {
        message: "Sınav süresi dolduğu için cevap kaydedilemedi.",
        variant: "error",
      };
    } else {
      insertLog({
        user_id: userId,
        exam_id: examId,
        user_name: userName,
        question_id: questionId,
        new_answer: userAnswer,
        error: "Serverda hata oluştu, cevap kaydedilemedi: " + error?.code,
      });

      return { message: "Sunucudan cevap alınamadı.", variant: "error" };
    }
  }
};

export const createAnswerFormAndStartExam = async (params) => {
  const { userId, examId } = params;
  try {
    const form_id = userId + "_" + examId;
    const anser_form = {
      exam_id: examId,
      user_id: userId,
      start_at: serverTimestamp(),
    };
    console.log("form_id: ", form_id);
    await setDoc(doc(db, "answer_forms", form_id), anser_form, { merge: true });
  } catch (error) {
    console.log("e:", error);
    alert("HATA: Cevap formu yaratılırken sunucuda hata oldu!");
  }
};

export const finishExam = async (form_id) => {
  let res = "";
  const answer_form = {
    finished_at: serverTimestamp(),
  };
  try {
    await setDoc(doc(db, "answer_forms", form_id), answer_form, {
      merge: true,
    });
    res = "ok";
  } catch (error) {
    console.log(error);
    alert("Sınavın süresi bitmiştir.");
    res = "time-over";
  }

  return res;
};

export const doSmth = async (params) => {
  try {
    // downloadKanguruMonthlyUsageStats();
    // changeMultipleAnswerForms();
    // addDataToPublicData();
    // create_tozos_exams_questions_and_booklets();
    // print_tozos_exam_stats();
    // create_oyun_exams_questions_and_booklets();
    // downloadCorrectAnswerPercentages();
    // print_oyun_exam_stats();
    // create_bayrakli_exams_questions_and_booklets();
    // create_ibb_exams_questions_and_booklets();
  } catch (error) {
    console.log(error);
  }
};

export const saveExam = async (exam) => {
  const examCopy = { ...exam };
  const id = exam.id;
  delete examCopy.id;

  try {
    await setDoc(doc(db, "exams", id), examCopy, { merge: true });
    return { variant: "success", message: "Sınav Başarıyla Kaydedildi." };
  } catch (error) {
    return { variant: "error", message: "Sınav Kaydedilirken Hata Oluştu." };
  }
};

export const saveQuestion = async (
  unchangedOriginalQuestion,
  changedQuestion,
  exam_id
) => {
  /*

    burası çok önemli ve belkide en karışık methodlardan birisi. şimdi başta burası tam olarak 3 part olarak işliyor. ilk 
    olarak Soru oluşturmak isteen küllanıcı sistemde oluşturulan sorulara girip ordan soru ekle butonuna geldiğinde karşısına
    bir modal çıkmakta. Bu modalda yeni soru eklemek için gerekli her bilgiyi biz almaktayız ve 
    await setDoc(doc(db, "questions", id), questionCopy); bu işlemle soruyu sisteme kaydediyoruz. Şimdi sonrasını bu
    işlemin altındaki yorumda anlatıyor olacağım.
  */
  console.log("question", changedQuestion);
  let newQuestions = {};
  let newExamQuestions = {};
  const questionCopy = { ...changedQuestion };
  const user = getAuth().currentUser;
  const imageFile = changedQuestion.imageFile;
  delete questionCopy.imageFile;
  let id;
  const now = new Date().getTime();
  if (!changedQuestion.id) {
    id = `${user.uid}${now}`;
    questionCopy.creator_id = user.uid;
  } else {
    id = changedQuestion.id;
  }
  if (changedQuestion.imageFile) {
    /*
      burdaki method çok önemli. işlemleri firebase dökümanından alarak hallettim fonksiyon içerisinde ne yaptığını anlatacağım
    */
    const url = await writeImageToStorage({
      file: imageFile,
      userId: user.uid,
      questionId: id,
    });
    questionCopy.question_image_path = url;
  } else {
    // daha önce varsa, ama şu an yoksa, var olan imajı sil:
    if (
      unchangedOriginalQuestion?.question_image_path &&
      !questionCopy.question_image_path
    ) {
      const isDeletionSuccessful = await deleteImageFileFromStorage(
        unchangedOriginalQuestion.question_image_path
      );

      if (!isDeletionSuccessful) {
        alert("Resim sistemden silinemiyor");
        return;
      }
    }
  }

  const updatedChoices = questionCopy.choices;
  const unchangedOriginalChoices = unchangedOriginalQuestion?.choices ?? {};

  if (updatedChoices) {
    for (let i = 0; i < Object.keys(updatedChoices).length; i++) {
      const letter = Object.keys(updatedChoices)[i];
      const updatedChoiceObject = updatedChoices[letter];
      const originalChoiceObject = unchangedOriginalChoices[letter];
      if (updatedChoiceObject.image_file) {
        let fileNameForChoice = id + "_" + letter;
        const url = await writeImageToStorage({
          file: updatedChoiceObject.image_file,
          userId: user.uid,
          questionId: fileNameForChoice,
        });
        updatedChoiceObject.choice_image = url;
        delete updatedChoiceObject.image_file; // image file'ı firebase'e yükledik. png'yi buradan silebiliriz.
      } else {
        // updated choice içinde imaj yoksa:
        if (
          originalChoiceObject?.choice_image &&
          !updatedChoiceObject.choice_image
        ) {
          //ama orijinalinde varsa, bunu sil:
          await deleteImageFileFromStorage(originalChoiceObject.choice_image);
        }
      }
      delete updatedChoiceObject.image_file; // image file'ı storage'a yükledik/sildik. Bu geçici attribute'ı veri tabanına yazmayalım.
    }
  }

  delete questionCopy.id;
  if (questionCopy.type === 0 && questionCopy.answer !== undefined)
    questionCopy.answer = "";
  if (questionCopy.type !== 1) {
    // Test değilse teste ait özellikleri silelim.
    // TODO: Burası hatalı, exam questions'tan silmiyor, sadece question objesinden siliyor. Exam questions'ta merge:true var.
    //TODO: Testin görselleri varsa onları da storaga'dan silmek gerek.
    delete questionCopy.choices;
    questionCopy.choice_columns = 1;
  }
  questionCopy.title && delete questionCopy.title;
  questionCopy.order && delete questionCopy.order;
  try {
    await setDoc(doc(db, "questions", id), questionCopy);
    /*
      Şimdi burdan sonrası için, eğer soruda edit işlemi yapıyorsan ve bu soru herhangi bir exam_questions document'ın içinde kayıtlı
      ise değişikliği hem sistemde qestion üstüne yaptırıyoruz (yukarıdaki kısımda) hemde tek tek tüm exam_questionları gezip soru
      orada da kayıtlı ise orada da edit işlemini yaptırıyoruz.  3. partı "if (exam_id) {" bu ifadeden sonra anlatıyorum.
    */
    const examQuestionQuery = query(
      collection(db, "exam_questions"),
      where("creator_id", "==", user.uid),
      where("questions." + id + ".creator_id", "==", user.uid)
    );
    const examQuestionsSS = await getDocs(examQuestionQuery);
    for (let i = 0; i < examQuestionsSS.docs.length; i++) {
      const eq_id = examQuestionsSS.docs[i].id;
      const eq_data = examQuestionsSS.docs[i].data();

      eq_data.questions[id].answer === undefined && delete questionCopy.answer;

      newQuestions = {
        [id]: {
          ...questionCopy,
        },
      };

      newExamQuestions = {
        questions: newQuestions,
      };

      await setDoc(doc(db, "exam_questions", eq_id), newExamQuestions, {
        merge: true,
      });
    }
    if (exam_id) {
      /**
       burası çok ilginç bir kısım. burası sisteme yeni bir soru eklenirken ama oluşturulumuş sorular kısmında soru ekle ile değil
       de oluşturulan sınavlarda yeni soru ekle butonunu kullanıysa soruyu hem sisteme ilk partta kaydediyor sonra burada gelip 
       exam_questiona kaydediyor.
       */
      let exam_question_id;
      let exam_question_data;
      const examQuestionsQuery = query(
        collection(db, "exam_questions"),
        where("creator_id", "==", user.uid),
        where("exam_id", "==", exam_id)
      );
      const ss = await getDocs(examQuestionsQuery);
      ss.forEach((doc) => {
        exam_question_id = doc.id;
        exam_question_data = doc.data();
      });
      delete questionCopy.answer;

      newQuestions = {
        [id]: {
          ...questionCopy,
        },
      };
      newExamQuestions = {
        questions: newQuestions,
      };
      if (exam_question_id) {
        await setDoc(
          doc(db, "exam_questions", exam_question_id),
          newExamQuestions,
          {
            merge: true,
          }
        );
      } else {
        newExamQuestions = {
          questions: newQuestions,
          exam_id,
          creator_id: user.uid,
        };
        await setDoc(doc(db, "exam_questions"), newExamQuestions);
      }
    }
  } catch (error) {
    console.log(error);
  }
};

//const user = getAuth().currentUser;

export const saveQuestionsToExamFromQuestionBank = async (
  questions,
  examId
) => {
  /**
   * Şimdi bu kısmı açıklamadan önce şunu belirmeliyim. Kullanıcı sisteme daha önce soru eklediyse biz sitemde kullanıcın eklediği
    sorulara ona ait soru bankası diyoruz. Şimdi methodu açıklamaya devam ediyorum. Bu methodda kullanıcı oluşturduğu sınava yeni
    soru eklemek istediğinde ama sıfırdan soru hazırlmaka istemediğinde, kendisine soru bankasından soru ekle şeklinde bir buton
    ayarladık. Bu butona basıp, kendi soru bankasındaki soruları görebilmekte ve ordan seçtiklerini exam_questiona ekleyebilmekte.
    Bu method bu işi yapıyorç. 
   */
  const user = getAuth().currentUser;
  let exam_question_id;
  // let exam_question_data;
  const examQuestionsQuery = query(
    collection(db, "exam_questions"),
    where("creator_id", "==", user.uid),
    where("exam_id", "==", examId)
  );
  const ss = await getDocs(examQuestionsQuery);
  ss.forEach((doc) => {
    exam_question_id = doc.id;
    // exam_question_data = doc.data();
  });
  const new_exam_questions = { questions };
  if (Object.keys(questions).length === 0) {
    alert("Lütfen Soru seçiniz");
  } else {
    try {
      await setDoc(
        doc(db, "exam_questions", exam_question_id),
        new_exam_questions,
        {
          merge: true,
        }
      );
    } catch (error) {
      console.log(error);
    }
  }
};

export const deleteQuestionFromExam = async (question_id, exam_id) => {
  /* 
    Bu kısımda ise kullanıcı exam quesiona bir soru ekledi ve onu kaldırmak istediğinde o soruyu sadece exam questionstan 
    kaldırıyoruz. Ama soru bankasında kalıyor.
  */
  const user = getAuth().currentUser;
  let exam_question_id;
  let exam_question_data;
  const examQuestionsQuery = query(
    collection(db, "exam_questions"),
    where("creator_id", "==", user.uid),
    where("exam_id", "==", exam_id)
  );
  const ss = await getDocs(examQuestionsQuery);
  ss.forEach((doc) => {
    exam_question_id = doc.id;
    exam_question_data = doc.data();
  });
  const questions = { ...exam_question_data.questions };
  delete questions[question_id];
  const exam_questions = { ...exam_question_data, questions };

  try {
    await setDoc(doc(db, "exam_questions", exam_question_id), exam_questions);
  } catch (error) {
    console.log(error);
  }
};

export const saveQuestionTitleAndOrder = async (
  question,
  exam_questions_id
) => {
  /**
   Bu method exam questionsa eklenen soruların başlığını ve sırasını düzenlenmesini sağlayan kısım. Yani başlık olarak
   Soru-1, Ek Soru-1 veya Ek Soru açıklaması gibi. Sıralama olarak sorunun 1. sırada mı 2. sırada mı veya başka bir sırada mı
   sınav esnasında gösterilmesini sağlar.
   */
  const examQuesionsDoc = await getDoc(
    doc(db, "exam_questions", exam_questions_id)
  );
  const exam_questions = examQuesionsDoc.data().questions ?? {};
  let questions = { ...exam_questions };
  questions[question.id] = { ...question };
  const exam_questions_to_db = { questions };
  try {
    await setDoc(
      doc(db, "exam_questions", exam_questions_id),
      exam_questions_to_db,
      {
        merge: true,
      }
    );
    alert("Kaydedildi.");
  } catch (error) {
    alert(
      "HATA: Soru bilgileri buklete kaydedilirken hata meydana geldi. Lütfen sayfayı yenileyiniz."
    );
    console.log(error);
  }
};

export const getAnswerForms = async (examId) => {
  /*
    cevap formunu çekmemizi sağlayan metot
  */
  let forms = [];
  const qry = query(
    collection(db, "answer_forms"),
    where("exam_id", "==", examId)
  );
  const querySnapshot = await getDocs(qry);
  querySnapshot.forEach((doc) => {
    // doc.id.toLocaleString("tr-TR")
    forms.push({ id: doc.id, ...doc.data() });
  });
  return forms;
};

const writeImageToStorage = async ({ file, userId, questionId } = {}) => {
  /*
    Burada soruda bir görsel varsa o görseli firebasedeki storagea kaydetim ordaki patini almamıza yarayan method. Firebase'in
    kendi dökümanından bakarak yaptım.
  */
  return new Promise(function (resolve, reject) {
    const storage = getStorage();
    const storageRef = ref(
      storage,
      "users/" + userId + "/questions/" + questionId
    );
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (err) => {
        console.log("error", err);
        alert("Resim karşı tarafa aktarılırken bir hata oldu...");
        reject();
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
};

export const getExamQuestionsByExamId = async (exam_id) => {
  const user = getAuth().currentUser;
  let exam_questions_id, exam_questions_data;
  const examQuestionsQuery = query(
    collection(db, "exam_questions"),
    where("creator_id", "==", user.uid),
    where("exam_id", "==", exam_id)
  );
  const ss = await getDocs(examQuestionsQuery);
  ss.docs.forEach((doc) => {
    exam_questions_id = doc.id;
    exam_questions_data = doc.data();
  });
  const exam_question = {
    id: exam_questions_id,
    ...exam_questions_data,
  };

  return exam_question;
};

export const releaseExamAnswers = async (exam_question) => {
  /*
    burda normalde sınav süresi bitmeden veya sınav kapanmadan biz cevapları exam_questionsa kaydetmiyoruz. Save question metodunu
    incelediğinde farkedeceksindir. Sınav bittikten sonra katılımcı sınavın cevaplarını yayınlamak isterse sınav cevaplarını yayınla
    şeklinde bir buton var. Ona basarak sınavın cevaplarını exam questiona kaydetme işlemini bu metot aracılığı ile gerçekleştiriyor
  */
  let new_exam_question = {};
  let questions = {};
  let questionIds = [];
  Object.keys(exam_question.questions).forEach((question) => {
    questionIds.push(question);
  });
  for (let i = 0; i < questionIds.length; i++) {
    const question = await getDoc(doc(db, "questions", questionIds[i])).catch(
      (e) => console.log(e)
    );
    const q_data = question.data();
    if (q_data.answer) {
      questions[questionIds[i]] = { ...q_data };
    }
  }
  new_exam_question = { questions };
  await setDoc(doc(db, "exam_questions", exam_question.id), new_exam_question, {
    merge: true,
  });
  console.log("releaseExam bitti");
};

// -------*-------

export const calculateExamQuestionsScores = async (exam_questions) => {
  /**
   Bu metot bize özel bi metot şu ana kadar sadece şampiyona sınavlarındaki soruların puanlanması için kullandık. Yani
   içerisindeki algoritma bizim geliştirdiğimiz bir puan hesaplama formülünün koda dökülmüş hali. Formülü Yavuz Selim Bey'e
   sorarsan sana göstericektir.
   */

  const { id, questions, exam_id } = exam_questions;
  console.log({ exam_questions });
  const answer_forms = await getAnswerForms(exam_id);
  let new_questions = {};
  for (const [qId, qObject] of Object.entries(questions)) {
    let count_true = 0;

    let thisIsNotARealQuestion = !qObject.type;

    let isHalfScoreQuestion = [
      //Bunlar Oyun2022'de 5 puanlık sorular. Bu amele yöntem yerine daha mantıklı bir sistem geliştirmek gerek.
      "iHw8zA3cddQYZUkimhOzErQkM5s11677657599215",
      "if2023_c5_c6_c7_c8_q7_a",
      "if2023_c9_c10_c11_c12_q7_a",
      "iHw8zA3cddQYZUkimhOzErQkM5s11677657612638",
      "if2023_c5_c6_c7_c8_q7_b",
      "if2023_c9_c10_c11_c12_q7_b",
    ].includes(qId);

    let isQuarterScoreQuestion = [
      //Bunlar Oyun2022'de 2.5 puanlık sorular. Bu amele yöntem yerine daha mantıklı bir sistem geliştirmek gerek.
      "iHw8zA3cddQYZUkimhOzErQkM5s11677656960715",
      "if2023_c5_c6_c7_c8_q6_a",
      "if2023_c9_c10_c11_c12_q6_a",
      "iHw8zA3cddQYZUkimhOzErQkM5s11677657206874",
      "if2023_c5_c6_c7_c8_q6_b",
      "if2023_c9_c10_c11_c12_q6_b",
      "iHw8zA3cddQYZUkimhOzErQkM5s11677657223242",
      "if2023_c5_c6_c7_c8_q6_c",
      "if2023_c9_c10_c11_c12_q6_c",
      "iHw8zA3cddQYZUkimhOzErQkM5s11677657235029",
      "if2023_c5_c6_c7_c8_q6_d",
      "if2023_c9_c10_c11_c12_q6_d",
    ].includes(qId);

    if (!thisIsNotARealQuestion) {
      for (let i = 0; i < answer_forms?.length; i++) {
        const answer = answer_forms[i][qId]?.response.trim();
        const correct_answers = qObject.answer.split("&");
        correct_answers.forEach((a) => {
          if (answer === a.trim()) {
            count_true++;
          }
        });

        let d = (count_true * 10.0) / answer_forms.length;
        let question_score = 20 - d;

        if (isHalfScoreQuestion) {
          question_score = question_score / 2;
        } else if (isQuarterScoreQuestion) {
          question_score = question_score / 4;
        }
        question_score = Math.round((1000 * question_score).toFixed(3)) / 1000; // pure js ile 3 haneye kadar round yapmak bu kadar zor!

        new_questions[qId] = { ...qObject, question_score };
      }
    } else new_questions[qId] = { ...qObject };
  }
  const new_exam_questions = { questions: new_questions };
  console.log("new_exam_questions: ", new_exam_questions);

  await setDoc(doc(db, "exam_questions", id), new_exam_questions, {
    merge: true,
  });

  alert("bitti");
};

export const setScoreToExamQuestions = async (
  question_score,
  exam_questions
) => {
  const { id, questions } = exam_questions;
  let new_questions = {};
  for (const [key, value] of Object.entries(questions)) {
    let thisIsNotARealQuestion = !value.type; // type 0 ise ya da hiç belirtilmemişse bu gerçek bir soru sayılmaz, puan hesaplanmaz.

    if (!thisIsNotARealQuestion) {
      new_questions[key] = { ...value, question_score };
    } else new_questions[key] = { ...value };
  }
  const new_exam_questions = { questions: new_questions };

  try {
    if (question_score > 0) {
      await setDoc(doc(db, "exam_questions", id), new_exam_questions, {
        merge: true,
      });
      alert(
        "Tüm sorulara " +
          question_score +
          " puan verilerek kullanacılara yayınlandı. Artık kullanıcıların toplam puanını hesaplatabilirsiniz."
      );
    } else {
      alert("Lütfen geçerli bir sayı giriniz!!");
    }
  } catch (error) {
    alert(
      "Soru puanları kaydedilirken hata meydana geldi. Sınav düzenleme ekranını yenileyerek son durumu kontrol etmeniz önerilir! "
    );
  }
};

export const calculateUserExamTotalScores = async (
  exam_id,
  progressCallback
) => {
  /**
   bu method bizim yine sadece tozoş sınavlarında kullandığımız bir method. Katılımcıların soru puanlarına göre doğru yanıt verdiği
   soruların puanlarının toplanması ile toplam aldıkları puanları bulmamızı sağlıyor.
   */
  const exam_questions = await getExamQuestionsByExamId(exam_id);
  const answer_forms = await getAnswerForms(exam_id);

  const { questions } = exam_questions;
  let formsWithTotalScores = [];

  for (let i = 0; i < answer_forms.length; i++) {
    let new_answer_form = {},
      id;
    let total_score = 0,
      total_score_additional = 0,
      total_score_additional2 = 0;

    if (answer_forms[i]?.result_type !== "disqualified") {
      for (const [key, value] of Object.entries(questions)) {
        let thisIsNotARealQuestion = !value.type; // type 0 ise ya da hiç belirtilmemişse bu gerçek bir soru sayılmaz, puan hesaplanmaz.

        if (thisIsNotARealQuestion) continue;

        const answer = answer_forms[i][key]?.response.trim();
        const correct_answers = value.answer.split("&");
        let usersPointFromThisQuestion = 0;

        correct_answers.forEach((a) => {
          if (answer === a.trim()) {
            usersPointFromThisQuestion = value.question_score;
          }
        });

        if (value.name?.includes("_ek")) {
          total_score_additional += usersPointFromThisQuestion;
          // total_score_additional2 += usersPointFromThisQuestion; // Bazı sınavlarda ek-2 oluyor. Onun condition'larını buraya kodlamak gerek.
        } else {
          total_score += usersPointFromThisQuestion;
        }
      }
    }
    // else {
    //   new_answer_form = {
    //     ...answer_forms[i],
    //     total_score,
    //     total_score_additional,
    //     result:
    //       "Hatalı sınıf seçtiğiniz için diskalifiye edildiniz. Oyun 2022'ye https://oyun.tzv.org.tr/ linkinden direkt ulaşıp ücretsiz olarak kaydınızı gerçekleştirebilirsiniz.",
    //   };
    // }
    id = answer_forms[i].id;

    // let result_text = "";

    // const isThisTozosExam = false;//TODO: Buraya başka bir yöntem düşünmeliyiz.

    //     if (isThisTozosExam) {
    //       result_text = `Puanınız (İlk 10 Sorudan): ${total_score.toFixed(3)}
    // 1. Grup Ek Soru Puanınız: ${total_score_additional.toFixed(3)}
    // 2. Grup Ek Soru Puanınız: ${total_score_additional2.toFixed(3)}`;
    //     } else {
    //       let scoreText = `Puanınız: ${total_score.toFixed(3)}`;
    //       let rankText = `Sıralamanız: ${rank.toFixed(3)}`
    //       result_text = ""};

    new_answer_form = {
      ...answer_forms[i],
      total_score,
      total_score_additional,
      total_score_additional2,
      // result: result_text,
    };

    formsWithTotalScores.push(new_answer_form);
  }

  formsWithTotalScores = formsWithTotalScores.sort((f1, f2) => {
    if (f1.total_score !== f2.total_score)
      return f2.total_score - f1.total_score;
    else if (f1.total_score_additional !== f2.total_score_additional)
      return (
        (f2.total_score_additional ?? 0) - (f1.total_score_additional ?? 0)
      );
    else if (f1.total_score_additional2 !== f2.total_score_additional2)
      return (
        (f2.total_score_additional2 ?? 0) - (f1.total_score_additional2 ?? 0)
      );
    else if (f1.last_response_updated_at !== f2.last_response_updated_at)
      return (
        (f1.last_response_updated_at?.toDate() ?? new Date("3000-01-01")) -
        (f2.last_response_updated_at?.toDate() ?? new Date("3000-01-01"))
      );
    else return f1.id - f2.id; // Sınavı başlatıp da hiç cevap kaydetmeyenler var, butona her basışta bunlara farklı sıra vermesini engellemek için id'ye göre sıralatıyorum. Tutarlı olsun diye.
  });

  for (let i = 0; i < formsWithTotalScores.length; i++) {
    const new_answer_form = formsWithTotalScores[i];

    const id = new_answer_form.id;
    delete new_answer_form.id;
    new_answer_form.rank = i + 1;
    // if (i > 0) { // TODO : Kanguru bu kısmın açılmasını istiyor...
    //   const previous_answer_form = formsWithTotalScores[i - 1];
    //   if (previous_answer_form.total_score === new_answer_form.total_score)
    //     new_answer_form.rank = previous_answer_form.rank;
    // }
    await setDoc(doc(db, "answer_forms", id), new_answer_form, {
      merge: true,
    });
    if (i % 10 === 0 || i === answer_forms.length - 1)
      progressCallback(i, answer_forms.length - 1);
  }
  alert("Total puanlar hesaplandı.");
};

export const calculateUserExamTotalScoresKanguru = async (
  // Boş cevaplara +1 puan verir.
  exam_id,
  progressCallback
) => {
  const exam_questions = await getExamQuestionsByExamId(exam_id);
  const answer_forms = await getAnswerForms(exam_id);

  const { questions } = exam_questions;
  let formsWithTotalScores = [];

  for (let i = 0; i < answer_forms.length; i++) {
    let new_answer_form = {},
      id;
    let total_score = 0;

    if (answer_forms[i]?.result_type !== "disqualified") {
      for (const [key, value] of Object.entries(questions)) {
        let thisIsNotARealQuestion = !value.type; // type 0 ise ya da hiç belirtilmemişse bu gerçek bir soru sayılmaz, puan hesaplanmaz.
        if (!thisIsNotARealQuestion) {
          let questionScore = value.question_score ?? 0;
          // Kanguru, iptal olan sorular için herkese tam puan vermek istiyor.
          // Bu durumda soru iptal edilirse soru puanını negatif olarak girdiklerinde o sorudan herkes tam puan alır.
          //Boş bıraksa bile.
          if (questionScore < 0) {
            total_score += -1 * questionScore;
            continue;
          }

          const answer = answer_forms[i][key]?.response.trim();
          const correct_answers = value.answer.split("&");
          correct_answers.forEach((a) => {
            if (!!answer) {
              if (answer === a.trim()) {
                total_score += value.question_score;
              }
            } else total_score += 1;
          });
        }
      }
    }

    id = answer_forms[i].id;

    new_answer_form = {
      ...answer_forms[i],
      total_score,
    };

    formsWithTotalScores.push(new_answer_form);
  }

  formsWithTotalScores = formsWithTotalScores.sort(
    (f1, f2) => f2.total_score - f1.total_score
  );

  for (let i = 0; i < formsWithTotalScores.length; i++) {
    const new_answer_form = formsWithTotalScores[i];

    const id = new_answer_form.id;
    delete new_answer_form.id;
    new_answer_form.rank = i + 1;
    if (i > 0) {
      const previous_answer_form = formsWithTotalScores[i - 1];
      if (previous_answer_form.total_score === new_answer_form.total_score)
        new_answer_form.rank = previous_answer_form.rank;
    }
    await setDoc(doc(db, "answer_forms", id), new_answer_form, {
      merge: true,
    });
    if (i % 10 === 0 || i === answer_forms.length - 1)
      progressCallback(i, answer_forms.length - 1);
  }
};

export const calculateUserExamTotalScoresTZV = async (
  exam_id,
  progressCallback
) => {
  /**
   bu method bizim yine sadece tozoş sınavlarında kullandığımız bir method. Katılımcıların soru puanlarına göre doğru yanıt verdiği
   soruların puanlarının toplanması ile toplam aldıkları puanları bulmamızı sağlıyor.
   */
  const exam_questions = await getExamQuestionsByExamId(exam_id);
  const answer_forms = await getAnswerForms(exam_id);
  const { questions } = exam_questions;
  let new_answer_form = {},
    id;
  for (let i = 0; i < answer_forms.length; i++) {
    let total_score = 0,
      total_score_additional = 0,
      total_score_additional2 = 0;

    if (answer_forms[i]?.result_type !== "disqualified") {
      for (const [key, value] of Object.entries(questions)) {
        let thisIsNotARealQuestion = !value.type; // type 0 ise ya da hiç belirtilmemişse bu gerçek bir soru sayılmaz, puan hesaplanmaz.
        if (!thisIsNotARealQuestion) {
          if (value.name?.includes("_ek")) {
            const answer = answer_forms[i][key]?.response.trim();
            const correct_answers = value.answer.split("&");
            correct_answers.forEach((a) => {
              if (answer === a.trim()) {
                if (
                  value.name.includes("_ek1_") ||
                  value.name.includes("_ek2_") ||
                  value.name.includes("_ek3_") ||
                  value.name.includes("_ek4_") ||
                  value.name.includes("_ek5_")
                )
                  total_score_additional += value.question_score;
                else total_score_additional2 += value.question_score;
              }
            });
            // if (answer === value.answer) {
            //   total_score_additional += value.question_score;
            // }
          } else {
            const answer = answer_forms[i][key]?.response.trim();
            const correct_answers = value.answer.split("&");
            correct_answers.forEach((a) => {
              if (answer === a.trim()) {
                total_score += value.question_score;
              }
            });
            // if (answer === value.answer) {
            //   total_score += value.question_score;
            // }
          }
        }
      }
    }
    // else {
    //   new_answer_form = {
    //     ...answer_forms[i],
    //     total_score,
    //     total_score_additional,
    //     result:
    //       "Hatalı sınıf seçtiğiniz için diskalifiye edildiniz. Oyun 2022'ye https://oyun.tzv.org.tr/ linkinden direkt ulaşıp ücretsiz olarak kaydınızı gerçekleştirebilirsiniz.",
    //   };
    // }
    id = answer_forms[i].id;

    let result_text = "";

    const isThisTozosExam = false; //TODO: Buraya başka bir yöntem düşünmeliyiz.

    if (!isThisTozosExam) {
      result_text = `Puanınız (İlk 10 Sorudan): ${total_score.toFixed(3)}
1. Grup Ek Soru Puanınız: ${total_score_additional.toFixed(3)}
2. Grup Ek Soru Puanınız: ${total_score_additional2.toFixed(3)}`;
    } else {
      // let scoreText = `Puanınız: ${total_score.toFixed(3)}`;
      // let rankText = `Sıralamanız: ${rank.toFixed(3)}`
      // result_text = ""
      window.alert("tozoş değil");
    }

    new_answer_form = {
      ...answer_forms[i],
      total_score,
      total_score_additional,
      total_score_additional2,
      result: result_text,
    };
    delete new_answer_form.id;
    await setDoc(doc(db, "answer_forms", id), new_answer_form, {
      merge: true,
    });
    if (i % 20 === 0 || i === answer_forms.length - 1)
      progressCallback(i, answer_forms.length - 1);
  }
};

export const publishExamResults = async (
  exam_id,
  progressCallback,
  publishScore,
  publishRank,
  publishParticipants
) => {
  const answer_forms = await getAnswerForms(exam_id);
  for (let i = 0; i < answer_forms.length; i++) {
    const form = answer_forms[i];
    const totalScore = form.total_score ?? 0;
    let totalScoreFixed = totalScore.toFixed(3);
    if (totalScore == totalScoreFixed)
      // Burada bilinçli olarak == koyduk. 3 tane koysak hatalı olur.
      totalScoreFixed = totalScore; // eğer fixed puan 12.000 ise, bunun yerine 12 yazdırıyoruz. Kanguru sınavları için önemli bu, onlarda küsürat yok.
    let scoreText = `Puanınız: ${totalScoreFixed}`;
    const additionalScore = form.total_score_additional ?? 0;
    let additionalScoreText = `Ek Soru Puanınız: ${additionalScore.toFixed(3)}`;
    let rankText = `Sıralamanız: ${form.rank}`;
    let participantsCountText = `Katılımcı Sayısı: ${answer_forms.length}`;

    let textsForPrint = [];
    if (publishScore === true) {
      textsForPrint.push(scoreText);
      if (additionalScore > 0) textsForPrint.push(additionalScoreText);
    }
    if (publishRank === true) textsForPrint.push(rankText);
    if (publishParticipants === true) textsForPrint.push(participantsCountText);

    let result = "";
    for (let i = 0; i < textsForPrint.length; i++) {
      result += textsForPrint[i];
      if (i !== textsForPrint.length - 1) result += `\n`;
    }
    const newForm = { ...form, result };
    const id = newForm.id;
    delete newForm.id;
    await setDoc(doc(db, "answer_forms", id), newForm, {
      merge: true,
    });

    if (i % 10 === 0 || i === answer_forms.length - 1)
      progressCallback(i, answer_forms.length - 1);
  }
  console.log("publishScore", publishScore, publishRank, publishParticipants);
  if (
    publishScore === false &&
    publishRank === false &&
    publishParticipants === false
  ) {
    window.alert(
      "Hiçbir seçim yapmadınız. Puan, sıralama ve katılımcı sayısı bilgileri yayınlanmayacaktır!!"
    );
  } else {
    window.alert("Seçimleriniz güncellenerek yayınlanmıştır!!");
  }
};

export const getCouponData = async (code) => {
  let res = {};
  try {
    const docRef = doc(db, "coupons", code);
    const docSnap = await getDoc(docRef);
    if (docSnap.data()) {
      const coupon = docSnap.data();
      // let exam_id = "",
      //   exam_questions_id = "",
      let exams = [],
        paginationExamIds = [],
        allExams = [];

      for (const [key, value] of Object.entries(coupon.exam_ids)) {
        let exam_obj = {};
        exam_obj = { ...value, exam_id: key };
        exams.push(exam_obj);
      }

      exams.sort((a, b) => {
        const add_date_a = a.add_date ? new Date(a.add_date.toDate()) : 0;
        const add_date_b = b.add_date ? new Date(b.add_date.toDate()) : 0;
        if (add_date_a > add_date_b) {
          return -1;
        }
        if (add_date_b > add_date_a) {
          return 1;
        }
        return 0;
      });

      for (let i = 0; i < exams.length; i++) {
        paginationExamIds.push(exams[i].exam_id);
        if (paginationExamIds.length === 10 || i === exams.length - 1) {
          const examQuery = query(
            collection(db, "exams"),
            where(documentId(), "in", paginationExamIds)
          );
          const querySnapshot = await getDocs(examQuery);
          querySnapshot.forEach((doc) => {
            let ex = {
              ...doc.data(),
              id: doc.id,
              exam_questions_id: coupon.exam_ids[doc.id].exam_questions_id,
            };
            if (ex.start_date) ex.start_date = ex.start_date.toDate();
            if (ex.end_date) ex.end_date = ex.end_date.toDate();

            allExams.push(ex);
          });
          paginationExamIds = [];
        }
      }

      // exam_id = exams[0].exam_id;
      // exam_questions_id = exams[0].exam_questions_id;

      res = {
        exams: allExams,
        name: coupon.name,
      };
    } else res = { message: "TC Numaranız Sınav Sistemine Kayıtlı Değildir!" };
  } catch (error) {
    res = { message: "TC numarası sorgulaması esnasında hata meydana geldi!" };
    console.log("error", error);
  }

  return res;
};

export const addParticipantsToExam = async (
  examId,
  examQuestionsId,
  rows,
  percentageSetter
) => {
  if (!examId || !examQuestionsId) {
    alert("Sınav ya da Buklet ID bulunamadı!");
  } else {
    let errors = [];
    console.log(examId + "  " + examQuestionsId);
    percentageSetter(0);
    for (let i = 0; i < rows.length; i++) {
      if ((i + 1) % 20 === 0 || i === rows.length - 1) {
        const percentage = ((i + 1) * 100.0) / rows.length;
        percentageSetter(percentage);
      }

      const u = rows[i];

      const tc = u["TC"]?.toString()?.trim();
      const name = u["AD-SOYAD"]?.toString()?.trim();

      if (!tc) {
        errors.push("Şu satırda TC bulunamadı: " + (i + 1) + ". satır");
        continue;
      } else if (tc.length !== 11) {
        errors.push("11 haneli değil: " + tc);
        continue;
      } else if (isNaN(tc)) {
        errors.push("Tc hatalı girilmiş:" + tc);
        continue;
      }

      let objectForUpdate = {
        exam_ids: {
          [examId]: {
            exam_questions_id: examQuestionsId,
            add_date: serverTimestamp(),
          },
        },
      };

      if (name) {
        objectForUpdate.name = name;
      }

      try {
        await setDoc(doc(db, "coupons", tc), objectForUpdate, { merge: true });
      } catch (error) {
        errors.push("Bilinmeyen Hata: " + tc);
      }
    }

    let resultText = rows.length + " adet satır sisteme eklendi.";
    if (errors.length > 0) {
      resultText = "İşlem tamamlandı fakat aşağıdaki satırlar eklenemedi ";
      errors.forEach((e) => {
        resultText = resultText + "\n" + e;
      });
    }

    alert(resultText);
  }
};

export const fetchPublicData = async () => {
  const publicData = (await getDoc(doc(db, "settings/public_data"))).data();

  return {
    ...publicData,
    home_page_exams: publicData.home_page_exams.map((e) => {
      return {
        ...e,
        start_date: e.start_date?.toDate(),
        end_date: e.end_date?.toDate(),
      };
    }),
  };
};

export const purchaseExam = async () => {
  // sınavın ücreti yoksa doğrudan user_exams tablosuna insert edelim.
  // sınavın ücretliyse başka çare düşünmemiz lazım
};

export const fetchVendorsPublicData = async (vendorKey) => {
  const vendorObjects = [];

  const vendorQuery = query(
    collection(db, "vendors_public_data"),
    where("vendor_key", "==", vendorKey)
  );
  try {
    const ss = await getDocs(vendorQuery);

    ss.forEach((doc) => {
      vendorObjects.push({
        ...doc.data(),
        vendor_user_id: doc.id,
      });
    });
  } catch (error) {
    console.log("error", error);
  }
  if (vendorObjects.length > 1) {
    // alert("Sınav yöneticisine ait bilgiler çekilirken hata oldu!");
    return {};
  } else if (vendorObjects.length === 0) {
    alert("Sınav yöneticisine ait bilgi bulunamadı!");
    return {};
  } else return vendorObjects[0];
};

export const changeVendorsPublicData = async (vendors_public_data) => {
  try {
    const user = getAuth().currentUser;
    await setDoc(
      doc(db, "vendors_public_data", user.uid),
      { ...vendors_public_data },
      { merge: true }
    );
  } catch (error) {
    console.log("errorverdi", error);
  }
};

const deleteImageFileFromStorage = async (unchangedOriginalQuestionUrl) => {
  const storage = getStorage();

  let storageRef = ref(storage, unchangedOriginalQuestionUrl);

  try {
    console.log("unchangedOriginalQuestion: ", unchangedOriginalQuestionUrl);
    await deleteObject(storageRef);
    return true;
  } catch (error) {
    alert("Dosya silinirken hata meydana geldi!");
    console.log("hataa");
    console.log(error);
    return false;
  }
};

const changeMultipleAnswerForms = async () => {
  // Bazı sınavlarda bazı yarışmacılar cevap girişlerinde textbox'a nokta, virgül vs. karakter giriyor, cevapları hatalı olarak değerlendiriliyor.
  // Bunları doğru kabul etmk istersek, bu kullanıcıları excelde süzüp cevap formlarını güncelliyoruz ki o sorudan puan alabilsinler
  // Bu fonksiyon bu işlemi kolaylaştırmak için yazıldı.
  const data = [
    {
      formIds: ["9VhQPH0dgRP2wDur7UIzTb7hysb2_oyun2022_yf_gencler_e85a3d8658"],
      qId: "oyun2022_yf_s9_g",
      newAnswer: "BOTANİK",
    },
  ];

  for (const obj of data) {
    for (const form_id of obj.formIds) {
      const q = query(doc(db, "answer_forms/" + form_id));
      const d = await getDoc(q);

      const oldAnswerObject = d.data()[obj.qId];
      if (oldAnswerObject) {
        await setDoc(
          doc(db, "answer_forms", form_id),
          {
            [obj.qId]: {
              ...oldAnswerObject,
              response: obj.newAnswer,
            },
          },
          { merge: true }
        );
        console.log("form_id");
      } else {
        console.log("---- form bulunamadı: ", form_id);
      }
    }
  }
};

const downloadKanguruMonthlyUsageStats = async () => {
  // Kangurunun aylık kullanım istatistiklerini çıkarırır
  console.clear();
  const startDate = new Date("2024-03-14");
  const endDate = new Date("2025-12-12");
  // const creatorId = "A5t6cBK0mKUfITOfra2HAvrbwfr1"; // kanguru
  const creatorId = "Shekb1mS0AUocjGJbLRpRgBZwlz2"; // çekirge

  let kanguruExams = [];

  const examQuery = query(
    collection(db, "exams"),
    where("creator_id", "==", creatorId)
  );
  const querySnapshot = await getDocs(examQuery);
  querySnapshot.forEach((doc) => {
    let ex = { ...doc.data(), id: doc.id };
    if (ex.start_date) ex.start_date = ex.start_date.toDate();
    if (ex.end_date) ex.end_date = ex.end_date.toDate();

    kanguruExams.push(ex);
  });

  console.log("kanguruExams", kanguruExams);

  let stats = [
    [
      "Sınav Id",
      "Sınav Adı",
      "Sınav Alt Başlığı",
      "Katılımcı Sayısı (Admin Hariç)",
    ],
  ];

  for (let i = 0; i < kanguruExams.length; i++) {
    const ex = kanguruExams[i];
    console.log("ex", ex);
    let formCount = 0;

    const formQuery = query(
      collection(db, "answer_forms"),
      where("exam_id", "==", ex.id)
      // where("start_at", ">=", startDate),
      // where("start_at", "<=", endDate)
    );
    const ss = await getDocs(formQuery);
    ss.forEach((doc) => {
      const formData = doc.data();
      const formStart = formData.start_at?.toDate();
      if (
        formStart &&
        formStart >= startDate &&
        formStart <= endDate &&
        formData.user_id !== creatorId
      )
        formCount++;
    });

    if (formCount > 0) {
      stats.push([ex.id, ex.header, ex.description, formCount]);
    }
  }
  arrayToCsv(stats, "cekirgeler_2024.csv");

  console.log("stats", stats);
};

const create_oyun_exams_questions_and_booklets = async (params) => {
  try {
    const creator_id = "WW1sajDgs9ZGtzQa117K3jzjOig2";
    const exam_ids = [
      "oyun2024_YF_minikler_FJ9X5y",
      "oyun2024_YF_cocuklar_9JUd5y",
      "oyun2024_YF_gencler_fH8t2Z",
      "oyun2024_YF_yetiskinler_yF8tyZ",
      "ouyn2024_YF_emektarlar_nSd4rQ",
    ];

    const exam_names = [
      "MİNİKLER",
      "ÇOCUKLAR",
      "GENÇLER",
      "YETİŞKİNLER",
      "EMEKTARLAR",
    ];

    for (let i = 0; i < exam_ids.length; i++) {
      const e = exam_ids[i];
      let exam_object = {
        creator_id,
        start_date: new Date(2024, 8, 31, 13),
        header: "TÜRKİYE 29. ZEKA OYUNLARI YARIŞMASI",
        end_date: new Date(2024, 8, 31, 14, 30),
        status: 1,
        description: exam_names[i] + " YARI FİNAL SINAVI",
      };
      await setDoc(doc(db, "exams", e), exam_object);
    }

    const exam_questions_ids = [
      "oyun2024_YF_minikler_eq_YXZ2RN",
      "oyun2024_YF_cocuklar_eq_FYU1AZ",
      "oyun2024_YF_gencler_eq_GEe4Nb",
      "oyun2024_YF_yetiskinler_eq_xpQ245",
      "oyun2024_YF_emektarlar_eq_tSz56T",
    ];

    for (let i = 0; i < exam_questions_ids.length; i++) {
      const q = exam_questions_ids[i];
      let eqObject = {
        creator_id,
        exam_id: exam_ids[i],
      };
      // await deleteDoc(doc(db, "exam_questions", q));

      await setDoc(doc(db, "exam_questions", q), eqObject);
    }

    const questionList = [
      ["oyun2024yf_q1_m", "BOYALI KARELER", 1],
      ["oyun2024yf_q1_c", "BOYALI KARELER", 1],
      ["oyun2024yf_q1_g", "BOYALI KARELER", 1],
      ["oyun2024yf_q1_y", "BOYALI KARELER", 1],
      ["oyun2024yf_q1_e", "BOYALI KARELER", 1],

      ["oyun2024yf_q2_m_c", "ARABALAR", 2],
      ["oyun2024yf_q2_g_y_e", "KARE SEÇİMİ", 2],

      ["oyun2024yf_q3_m_c", "YOLLAR", 3],
      ["oyun2024yf_q3_g_y_e", "ÜÇ SAYI", 3],

      ["oyun2024yf_q4_m", "SAYI BLOKLARI", 4],
      ["oyun2024yf_q4_c", "SAYI BLOKLARI", 4],
      ["oyun2024yf_q4_g", "SAYI BLOKLARI", 4],
      ["oyun2024yf_q4_y", "SAYI BLOKLARI", 4],
      ["oyun2024yf_q4_e", "SAYI BLOKLARI", 4],

      ["oyun2024yf_q5_m", "YÜZ SAYISI", 5],
      ["oyun2024yf_q5_c", "YÜZ SAYISI", 5],
      ["oyun2024yf_q5_g", "BİN SAYISI", 5],
      ["oyun2024yf_q5_y", "BİN SAYISI", 5],
      ["oyun2024yf_q5_e", "BİN SAYISI", 5],

      ["oyun2024yf_q6_m", "SÖZCÜK TABLOLARI", 6],
      ["oyun2024yf_q6_c", "SÖZCÜK TABLOLARI", 6],
      ["oyun2024yf_q6_g", "SÖZCÜK TABLOLARI", 6],
      ["oyun2024yf_q6_y", "SÖZCÜK TABLOLARI", 6],
      ["oyun2024yf_q6_e", "SÖZCÜK TABLOLARI", 6],

      ["oyun2024yf_q7_m_c_g_y_e", "SORU İŞARETİ", 7],
      ["oyun2024yf_q8_m_c_g_y_e", "KAPLAR", 8],
      ["oyun2024yf_q9_m_c_g_y_e", "SİMETRİK BULMACA", 9],
      ["oyun2024yf_q10_m_c_g_y_e", "SAYI YERLEŞTİR", 10],

      ["oyun2024yf_ek_aciklama_m_c_g_y_e", "EK SORU AÇIKLAMASI", 10.1],

      ["oyun2024yf_ek11_m_c_g_y_e", "SÖZCÜK TAHMİNİ", 11],
      ["oyun2024yf_ek12_m_c_g_y_e", "SÖZCÜK TAHMİNİ", 12],
      ["oyun2024yf_ek13_m_c_g_y_e", "SÖZCÜK TAHMİNİ", 13],
      ["oyun2024yf_ek14_m_c_g_y_e", "SÖZCÜK TAHMİNİ", 14],
      ["oyun2024yf_ek15_m_c_g_y_e", "SÖZCÜK TAHMİNİ", 15],
      ["oyun2024yf_ek16_m_c_g_y_e", "SÖZCÜK TAHMİNİ", 16],
      ["oyun2024yf_ek17_m_c_g_y_e", "SÖZCÜK TAHMİNİ", 17],
      ["oyun2024yf_ek18_m_c_g_y_e", "SÖZCÜK TAHMİNİ", 18],
      ["oyun2024yf_ek19_m_c_g_y_e", "SÖZCÜK TAHMİNİ", 19],
      ["oyun2024yf_ek20_m_c_g_y_e", "SÖZCÜK TAHMİNİ", 20],
    ];

    for (let i = 0; i < questionList.length; i++) {
      console.log("eklenen soru: ", i);

      const [question_name, question_category, order] = questionList[i];

      // await deleteDoc(doc(db, "questions", question_name));
      // console.log(question_name + "deleted")
      const ex_codes = ["_m", "_c", "_g", "_y", "_e"];

      for (let i = 0; i < 5; i++) {
        let ex_code = ex_codes[i];
        let eqId = "";
        if (question_name.indexOf(ex_code) > -1) {
          eqId = exam_questions_ids[i];
          console.log("eqId: " + eqId);

          let qObject = {
            name: question_name,
            creator_id,
            question_category,
          };

          console.log({ qObject });

          // let titleLetter = "A";
          // if (question_name.indexOf("_b") > -1) titleLetter = "B";
          // else if (question_name.indexOf("_d") > -1) titleLetter = "D";
          // else if (
          //   question_name.indexOf("q6_c") > -1 ||
          //   question_name.indexOf("q7_c") > -1
          // )
          //   titleLetter = "C";

          await setDoc(doc(db, "questions", question_name), qObject);
          let title = "Soru " + order + ")";
          if (order > 10) title = "Ek Soru " + (order - 10) + ")";
          await setDoc(
            doc(db, "exam_questions", eqId),
            {
              questions: {
                [question_name]: {
                  ...qObject,
                  order,
                  title,
                },
              },
            },
            { merge: true }
          );
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
};

const create_bayrakli_exams_questions_and_booklets = async (params) => {
  try {
    const creator_id = "jxqJk4aZpbMfNUYhLMCYlpgSv972";
    const exam_ids = [
      "bayrakli2024_ilkokul_FdWh7R",
      "bayrakli2024_ortaokul_crGESx",
      "bayrakli2024_lise_qQTZxo",
    ];

    const exam_names = ["İLKOKUL", "ORTAOKUL", "LİSE"];

    for (let i = 0; i < exam_ids.length; i++) {
      const e = exam_ids[i];
      let exam_object = {
        creator_id,
        start_date: new Date(2024, 7, 18, 0, 1),
        header: "BAYRAKLI BELEDİYESİ ZEKA MARATONU YARIŞMASI",
        end_date: new Date(2024, 7, 19, 23, 59),
        status: 1,
        description: exam_names[i] + " ELEME SINAVI",
      };
      await setDoc(doc(db, "exams", e), exam_object);
    }

    const exam_questions_ids = [
      "bayrakli2024_ilkokul_eq_FdWh7l",
      "bayrakli2024_ortaokul_eq_crESwc",
      "bayrakli2024_lise_eq_qQfBxo",
    ];

    for (let i = 0; i < exam_questions_ids.length; i++) {
      const q = exam_questions_ids[i];
      let eqObject = {
        creator_id,
        exam_id: exam_ids[i],
      };
      // await deleteDoc(doc(db, "exam_questions", q));

      await setDoc(doc(db, "exam_questions", q), eqObject);
    }

    const questionList = [
      ["bayrakli2024_q1_i", "HARFLER", 1],
      ["bayrakli2024_q1_o", "HARFLER", 1],
      ["bayrakli2024_q1_l", "HARFLER", 1],

      ["bayrakli2024_q2_i", "SAYI BLOKLARI", 2],
      ["bayrakli2024_q2_o", "SAYI BLOKLARI", 2],
      ["bayrakli2024_q2_l", "SAYI BLOKLARI", 2],

      ["bayrakli2024_q3_i", "FENERLER", 3],
      ["bayrakli2024_q3_o", "FENERLER", 3],
      ["bayrakli2024_q3_l", "FENERLER", 3],

      ["bayrakli2024_q4_i_o_l", "RAKAM DEĞİŞTİR", 4],
      ["bayrakli2024_q5_i_o_l", "SÖZCÜK DAİRELERİ", 5],
      ["bayrakli2024_q6_i_o_l", "KUTULAR", 6],
      ["bayrakli2024_q7_i_o_l", "KÜP", 7],
      ["bayrakli2024_q8_i_o_l", "YAPBOZ", 8],
      ["bayrakli2024_q9_i_o_l", "KİBRİT İŞLEM", 9],
      ["bayrakli2024_q10_i_o_l", "TAMAMLA", 10],
    ];

    for (let i = 0; i < questionList.length; i++) {
      console.log("eklenen soru: ", i);

      const [question_name, question_category, order] = questionList[i];

      // await deleteDoc(doc(db, "questions", question_name));
      // console.log(question_name + "deleted")
      const ex_codes = ["_i", "_o", "_l"];

      for (let i = 0; i < 3; i++) {
        let ex_code = ex_codes[i];
        let eqId = "";
        if (question_name.indexOf(ex_code) > -1) {
          eqId = exam_questions_ids[i];
          console.log("eqId: " + eqId);

          let qObject = {
            name: question_name,
            creator_id,
            question_category,
          };

          console.log({ qObject });

          await setDoc(doc(db, "questions", question_name), qObject);
          await setDoc(
            doc(db, "exam_questions", eqId),
            {
              questions: {
                [question_name]: {
                  ...qObject,
                  order,
                  title: "Soru " + order + ")",
                },
              },
            },
            { merge: true }
          );
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
};

const create_ibb_exams_questions_and_booklets = async (params) => {
  try {
    //kullanıcı kayıt ol sayfasından kayıt oluyoruz. Kullanıcının uid i buraya veriliyor. appBar componentinde de doSmthButton kısmına ekleniyor.
    const creator_id = "PBDhA8VWovgVPPsiSYBEHkefWg92";
    const exam_ids = [
      "ibb2024_YF_cocuklar_FdRg6J",
      "ibb2024_YF_gencler_cLGPcZ",
      "ibb2024_YF_yetiskinler_QsAtNZ",
    ];

    const exam_names = ["ÇOCUKLAR", "GENÇLER", "YETİŞKİNLER"];

    for (let i = 0; i < exam_ids.length; i++) {
      const e = exam_ids[i];
      let exam_object = {
        creator_id,
        start_date: new Date(2024, 10, 26, 0, 1),
        header: "İSTANBUL BELEDİYESİ ZEKA OYUNLARI YARIŞMASI",
        end_date: new Date(2024, 10, 26, 23, 59),
        status: 1,
        description: exam_names[i] + " YARI FİNAL SINAVI",
      };
      await setDoc(doc(db, "exams", e), exam_object);
    }

    const exam_questions_ids = [
      "ibb2024_YF_cocuklar_eq_PdHh3t",
      "ibb2024_YF_gencler_eq_VrWbLa",
      "ibb2024_YF_yetiskinler_eq_kDfBMl",
    ];

    for (let i = 0; i < exam_questions_ids.length; i++) {
      const q = exam_questions_ids[i];
      let eqObject = {
        creator_id,
        exam_id: exam_ids[i],
      };
      // await deleteDoc(doc(db, "exam_questions", q));

      await setDoc(doc(db, "exam_questions", q), eqObject);
    }
    //soruları burada oluşturuyoruz.
    const questionList = [
      ["ibb2024yf_q1_c_g_y", "DOKUZ RAKAM", 1],
      ["ibb2024yf_q2_c_g_y", "SORU İŞARETİ", 2],
      ["ibb2024_q3_c_g_y", "OKLAR", 3],

      ["ibb2024yf_q4_c", "DİKDÖRTGENLER", 4],
      ["ibb2024yf_q4_g", "DİKDÖRTGENLER", 4],
      ["ibb2024_q4_y", "DİKDÖRTGENLER", 4],

      ["ibb2024yf_q5_c", "HEDEF TAHTASI", 5],
      ["ibb2024yf_q5_g", "HEDEF TAHTASI", 5],
      ["ibb2024yf_q5_y", "HEDEF TAHTASI", 5],

      ["ibb2024yf_q6_c", "EKSİK HARFLER", 6],
      ["ibb2024yf_q6_g", "EKSİK HARFLER", 6],
      ["ibb2024yf_q6_y", "EKSİK HARFLER", 6],

      ["ibb2024yf_q7_c", "LAMBALAR", 7],
      ["ibb2024yf_q7_g", "LAMBALAR", 7],
      ["ibb2024yf_q7_y", "LAMBALAR", 7],

      ["ibb2024yf_q8_c", "FENERLER", 8],
      ["ibb2024yf_q8_g", "FENERLER", 8],
      ["ibb2024yf_q8_y", "FENERLER", 8],

      ["ibb2024yf_q9_c", "İŞLEM TABLOSU", 9],
      ["ibb2024yf_q9_g", "İŞLEM TABLOSU", 9],
      ["ibb2024yf_q9_y", "İŞLEM TABLOSU", 9],

      ["ibb2024yf_q10_c", "CÜMLE BUL", 10],
      ["ibb2024yf_q10_g", "CÜMLE BUL", 10],
      ["ibb2024yf_q10_y", "CÜMLE BUL", 10],

      
      ["ibb2024yf_ek_aciklama_c_g_y", "EK SORU AÇIKLAMASI", 10.1],

      ["ibb2024yf_ek11_c_g_y", "SÖZCÜK TAHMİNİ", 11],
      ["ibb2024yf_ek12_c_g_y", "SÖZCÜK TAHMİNİ", 12],
      ["ibb2024yf_ek13_c_g_y", "SÖZCÜK TAHMİNİ", 13],
      ["ibb2024yf_ek14_c_g_y", "SÖZCÜK TAHMİNİ", 14],
      ["ibb2024yf_ek15_c_g_y", "SÖZCÜK TAHMİNİ", 15],
      ["ibb2024yf_ek16_c_g_y", "KÜP BLOKLAR", 16],
      ["ibb2024yf_ek17_c_g_y", "KÜP BLOKLAR", 17],
      ["ibb2024yf_ek18_c_g_y", "KÜP BLOKLAR", 18],
      ["ibb2024yf_ek19_c_g_y", "KÜP BLOKLAR", 19],
      ["ibb2024yf_ek20_c_g_y", "KÜP BLOKLAR", 20],
    ];

    for (let i = 0; i < questionList.length; i++) {
      console.log("eklenen soru: ", i);

      const [question_name, question_category, order] = questionList[i];

      // await deleteDoc(doc(db, "questions", question_name));
      // console.log(question_name + "deleted")
      const ex_codes = ["_c", "_g", "_y"];

      for (let i = 0; i < 3; i++) {
        let ex_code = ex_codes[i];
        let eqId = "";
        if (question_name.indexOf(ex_code) > -1) {
          eqId = exam_questions_ids[i];
          console.log("eqId: " + eqId);

          let qObject = {
            name: question_name,
            creator_id,
            question_category,
          };

          console.log({ qObject });

          await setDoc(doc(db, "questions", question_name), qObject);
          await setDoc(
            doc(db, "exam_questions", eqId),
            {
              questions: {
                [question_name]: {
                  ...qObject,
                  order,
                  title: "Soru " + order + ")",
                },
              },
            },
            { merge: true }
          );
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
};
const print_oyun_exam_stats = async () => {
  let categories = [
    "Minikler",
    "Çocuklar",
    "Gençler",
    "Yetişkinler",
    "Emektarlar",
  ];

  let examIds = [
    "oyun2024_YF_minikler_FJ9X5y",
    "oyun2024_YF_cocuklar_9JUd5y",
    "oyun2024_YF_gencler_fH8t2Z",
    "oyun2024_YF_yetiskinler_yF8tyZ",
    "ouyn2024_YF_emektarlar_nSd4rQ",
  ];

  let categoryCounts = [150, 301, 110, 379, 85];
  let allCounts = categoryCounts.reduce((a, b) => a + b, 0);
  let categoryAttendences = [];

  let attendeceTotal = 0;

  let result_text = "";

  for (const exId of examIds) {
    const answerForms = await getAnswerForms(exId);
    categoryAttendences.push(answerForms.length);
    attendeceTotal += answerForms.length;
  }

  for (let i = 0; i < categories.length; i++) {
    result_text += "\n\n--- " + categories[i] + " ---\n";
    result_text +=
      "Katılmaya hak kazanan: " +
      categoryCounts[i] +
      "\nKatılan: " +
      categoryAttendences[i] +
      "\nKatılım Oranı: %" +
      ((categoryAttendences[i] * 100.0) / categoryCounts[i]).toFixed(1);
  }

  result_text += "\n\n------- TOPLAM ------\n";

  result_text +=
    "Katılmaya hak kazanan: " +
    allCounts +
    " \nKatılan: " +
    attendeceTotal +
    " \nKatılım Oranı: %" +
    ((attendeceTotal * 100.0) / allCounts).toFixed(1);

  console.clear();

  console.log(result_text);
};

const print_tozos_exam_stats = async () => {
  console.clear();
  const examList = await fetchCreatedExamList("iHw8zA3cddQYZUkimhOzErQkM5s1");

  let bfExams = [];

  for (let i = 0; i < examList.length; i++) {
    const ex = examList[i];

    if (
      ex.description &&
      ex.description.indexOf("BÖLGE FİNALLERİ") > 0 &&
      ex.header.indexOf("2022-23") > -1
    ) {
      bfExams.push(ex);
    }
  }

  let formCounts = {
    1: [126, 0],
    2: [187, 0],
    3: [198, 0],
    4: [211, 0],
    5: [239, 0],
    6: [222, 0],
    7: [209, 0],
    8: [109, 0],
    9: [146, 0],
    10: [147, 0],
    11: [132, 0],
    12: [55, 0],
  };

  let allForms = {};

  for (let i = 0; i < bfExams.length; i++) {
    const ex = bfExams[i];

    const answerForms = await getAnswerForms(ex.id);

    let classAndDot = ex.description.split(" ")[0];
    let classStr =
      classAndDot.length === 2
        ? classAndDot.substring(0, 1)
        : classAndDot.substring(0, 2);
    let classId = parseInt(classStr);
    console.log("classId", classId);
    formCounts[classId][1] = answerForms.length;
    allForms[classId] = answerForms;
  }

  console.log("formCounts", formCounts);
  console.log("allForms: ", allForms);

  let totalUsers = 0;
  let totalKatilim = 0;
  for (let i = 0; i < Object.keys(formCounts).length; i++) {
    const classId = Object.keys(formCounts)[i];
    const arr = formCounts[classId];
    totalUsers += arr[0];
    totalKatilim += arr[1];
  }

  let resultStr = ``;
  resultStr += `Katılması gereken takım sayısı: ${totalUsers} \n`;
  resultStr += `Katılan takım sayısı: ${totalKatilim} \n`;
  resultStr += `Katılım oranı: ${(
    (totalKatilim * 100) /
    (totalUsers * 1.0)
  ).toFixed(1)} \n\n`;

  // console.log("Katılması gereken takım sayısı: ", totalUsers);
  // console.log("Katılan takım sayısı: ", totalKatilim);
  // console.log("Katılım oranı: ", totalKatilim / (totalUsers * 1.0));

  for (let i = 0; i < Object.keys(formCounts).length; i++) {
    const classId = Object.keys(formCounts)[i];
    const percentage =
      (formCounts[classId][1] * 100) / (formCounts[classId][0] * 1.0);
    resultStr += `Sınıf: ${classId}  Katılım oranı: ${percentage.toFixed(
      1
    )} \n`;
    // console.log("Sınıf: ", classId, "  Katılım oranı: ", percentage);
  }

  console.log(resultStr);
};

export const create_tozos_exams_questions_and_booklets = async (params) => {
  try {
    const creator_id = "iHw8zA3cddQYZUkimhOzErQkM5s1";

    const exam_ids = [
      "bf2023_sinif_1_azr8o81GO1PHo1hsdy4L",
      "bf2023_sinif_2_s6bifxLXBpKSf4QzOhgk",
      "bf2023_sinif_3_5ZqPsEcyGIPWNHbxzECo",
      "bf2023_sinif_4_OmQOkoh4EpwJp4OunMMK",
      "bf2023_sinif_5_GhjJCAQAeKV8R46aQQ0p",
      "bf2023_sinif_6_5xt0prF6R3wOSCa8sp99",
      "bf2023_sinif_7_cSq77TPqTS9UQcGtRGwL",
      "bf2023_sinif_8_dPCLXKopjxDcYrovQCia",
      "bf2023_sinif_9_knqacAbBtWjiCgQ516TP",
      "bf2023_sinif_10_0IwuxgPAm94UMc1kxKs5",
      "bf2023_sinif_11_Ya9gK9iwb6v8Xm6mjfqB",
      "bf2023_sinif_12_8KR0gNUoXjjdtfOblfLq",
    ];

    for (let i = 0; i < exam_ids.length; i++) {
      const e = exam_ids[i];
      let exam_object = {
        creator_id,
        start_date: new Date(2023, 3, 15, 13),
        header: "2022-23 TÜRKİYE OKULLAR ARASI ZEKA OYUNLARI ŞAMPİYONASI",
        end_date: new Date(2023, 3, 15, 14, 30),
        status: 1,
        show_last_response_time_on_screen: true,
        description: i + 1 + ". SINIFLAR BÖLGE FİNALLERİ SINAVI",
      };
      // await setDoc(doc(db, "exams", e), exam_object);
    }

    const exam_questions_ids = [
      "bf2023_sinif_1_eq_slwyllTwaVJVZDBNjmL2",
      "bf2023_sinif_2_eq_jvFnO58OYkl0retydbIC",
      "bf2023_sinif_3_eq_QpFkjPSpFOkABdvk2C5h",
      "bf2023_sinif_4_eq_m3wbdlasrxAA6rvyM6Zs",
      "bf2023_sinif_5_eq_jZRjgu0NUdF0qIkDJ0vs",
      "bf2023_sinif_6_eq_hIFhhc15zE4rUXdsKphH",
      "bf2023_sinif_7_eq_n1of7t8HtP46YEvAL7Uq",
      "bf2023_sinif_8_eq_s5ZHwKelbeApyhsVXoac",
      "bf2023_sinif_9_eq_cHxcCd1t1UzwCK5MqoWf",
      "bf2023_sinif_10_eq_gSAGzNG3olq7VPZpauks",
      "bf2023_sinif_11_eq_PMF6S1rmGxPZarnt72Gx",
      "bf2023_sinif_12_eq_7mvrfeOmmtdQjmljxXbK",
    ];

    for (let i = 0; i < exam_questions_ids.length; i++) {
      const q = exam_questions_ids[i];
      let eqObject = {
        creator_id,
        exam_id: exam_ids[i],
      };
      // await deleteDoc(doc(db, "exam_questions", q));

      // await setDoc(doc(db, "exam_questions", q), eqObject);
    }

    const questionList = [
      ["bf2023_c1_q1", "YOLLAR", 1],
      ["bf2023_c2_q1", "YOLLAR", 1],
      ["bf2023_c3_q1", "YOLLAR", 1],
      ["bf2023_c4_q1", "YOLLAR", 1],
      ["bf2023_c5_q1", "YOLLAR", 1],
      ["bf2023_c6_q1", "YOLLAR", 1],
      ["bf2023_c7_q1", "YOLLAR", 1],
      ["bf2023_c8_q1", "YOLLAR", 1],
      ["bf2023_c9_q1", "YOLLAR", 1],
      ["bf2023_c10_q1", "YOLLAR", 1],
      ["bf2023_c11_q1", "YOLLAR", 1],
      ["bf2023_c12_q1", "YOLLAR", 1],

      ["bf2023_c1_q2", "EŞİT TOPLAMLAR", 2],
      ["bf2023_c2_q2", "EŞİT TOPLAMLAR", 2],
      ["bf2023_c3_q2", "EŞİT TOPLAMLAR", 2],
      ["bf2023_c4_q2", "EŞİT TOPLAMLAR", 2],
      ["bf2023_c5_q2", "EŞİT TOPLAMLAR", 2],
      ["bf2023_c6_q2", "EŞİT TOPLAMLAR", 2],
      ["bf2023_c7_q2", "EŞİT TOPLAMLAR", 2],
      ["bf2023_c8_q2", "EŞİT TOPLAMLAR", 2],
      ["bf2023_c9_q2", "EŞİT TOPLAMLAR", 2],
      ["bf2023_c10_q2", "EŞİT TOPLAMLAR", 2],
      ["bf2023_c11_q2", "EŞİT TOPLAMLAR", 2],
      ["bf2023_c12_q2", "EŞİT TOPLAMLAR", 2],

      ["bf2023_c1_c2_q3", "İKİ SAYI", 3],
      ["bf2023_c3_c4_q3", "YAŞLAR", 3],
      ["bf2023_c5_c6_c7_c8_q3", "RENKLİ TOPLAR", 3],
      ["bf2023_c9_c10_c11_c12_q3", "KOŞUCULAR", 3],

      ["bf2023_c1_c2_q4", "SÖZCÜK ÇEMBERİ", 4],
      ["bf2023_c3_c4_q4", "SÖZCÜK ÇEMBERİ", 4],
      ["bf2023_c5_c6_c7_c8_q4", "SÖZCÜK ÇEMBERİ", 4],
      ["bf2023_c9_c10_c11_c12_q4", "SÖZCÜK ÇEMBERİ", 4],

      ["bf2023_c1_c2_q5", "SAYI BİLMECESİ", 5],
      ["bf2023_c3_c4_q5", "SAYI BİLMECESİ", 5],
      ["bf2023_c5_c6_c7_c8_q5", "SAYI BİLMECESİ", 5],
      ["bf2023_c9_c10_c11_c12_q5", "SAYI BİLMECESİ", 5],

      ["bf2023_c1_c2_q6", "DOĞRUCULAR-YANLIŞÇILAR", 6],
      ["bf2023_c3_c4_q6", "DOĞRUCULAR-YANLIŞÇILAR", 6],
      ["bf2023_c5_c6_c7_c8_q6", "DOĞRUCULAR-YANLIŞÇILAR", 6],
      ["bf2023_c9_c10_c11_c12_q6", "DOĞRUCULAR-YANLIŞÇILAR", 6],

      ["bf2023_c1_c2_q7", "DİKDÖRTGENLER", 7],
      ["bf2023_c3_c4_q7", "DİKDÖRTGENLER", 7],
      ["bf2023_c5_c6_c7_c8_q7", "DİKDÖRTGENLER", 7],
      ["bf2023_c9_c10_c11_c12_q7", "DİKDÖRTGENLER", 7],

      ["bf2023_c1_c2_c3_c4_q8", "CÜMLE BUL", 8],
      ["bf2023_c5_c6_c7_c8_q8", "CÜMLE BUL", 8],
      ["bf2023_c9_c10_c11_c12_q8", "CÜMLE BUL", 8],

      ["bf2023_c1_c2_c3_c4_q9", "HEDEF TAHTASI", 9],
      ["bf2023_c5_c6_c7_c8_q9", "HEDEF TAHTASI", 9],
      ["bf2023_c9_c10_c11_c12_q9", "HEDEF TAHTASI", 9],

      ["bf2023_c1_c2_c3_c4_c5_c6_c7_c8_c9_c10_c11_c12_q10", "SORU İŞARETİ", 10],
      [
        "bf2023_c1_c2_c3_c4_c5_c6_c7_c8_c9_c10_c11_c12_ek_explanation",
        "",
        10.1,
      ],
      [
        "bf2023_c1_c2_c3_c4_c5_c6_c7_c8_c9_c10_c11_c12_ekq1",
        "SAYI BLOKLARI",
        11,
      ],
      [
        "bf2023_c1_c2_c3_c4_c5_c6_c7_c8_c9_c10_c11_c12_ekq2",
        "SAYI BLOKLARI",
        12,
      ],
      [
        "bf2023_c1_c2_c3_c4_c5_c6_c7_c8_c9_c10_c11_c12_ekq3",
        "SAYI BLOKLARI",
        13,
      ],
      [
        "bf2023_c1_c2_c3_c4_c5_c6_c7_c8_c9_c10_c11_c12_ekq4",
        "SAYI BLOKLARI",
        14,
      ],
      [
        "bf2023_c1_c2_c3_c4_c5_c6_c7_c8_c9_c10_c11_c12_ekq5",
        "SAYI BLOKLARI",
        15,
      ],
      [
        "bf2023_c1_c2_c3_c4_c5_c6_c7_c8_c9_c10_c11_c12_ekq6",
        "SAYI BLOKLARI",
        16,
      ],
      [
        "bf2023_c1_c2_c3_c4_c5_c6_c7_c8_c9_c10_c11_c12_ekq7",
        "SAYI BLOKLARI",
        17,
      ],
      [
        "bf2023_c1_c2_c3_c4_c5_c6_c7_c8_c9_c10_c11_c12_ekq8",
        "SAYI BLOKLARI",
        18,
      ],
      [
        "bf2023_c1_c2_c3_c4_c5_c6_c7_c8_c9_c10_c11_c12_ekq9",
        "SAYI BLOKLARI",
        19,
      ],
      [
        "bf2023_c1_c2_c3_c4_c5_c6_c7_c8_c9_c10_c11_c12_ekq10",
        "SAYI BLOKLARI",
        20,
      ],
    ];

    for (let i = 0; i < questionList.length; i++) {
      console.log("eklenen soru: ", i);

      const [question_name, question_category, order] = questionList[i];

      // await deleteDoc(doc(db, "questions", question_name));
      // console.log(question_name + "deleted")
      for (let classId = 1; classId < 13; classId++) {
        if (question_name.indexOf("c" + classId + "_") > -1) {
          console.log("Soru: " + question_name + "  sınıf: " + classId);
          const eqId = exam_questions_ids[classId - 1];
          let qObject = {
            name: question_name,
            creator_id,
            question_category,
          };
          // await setDoc(doc(db, "questions", question_name), qObject);

          let q_title = "Soru " + order + ")";
          if (order > 10) {
            q_title = "Ek Soru " + (order - 10) + ")";
          }

          if (order == 10.1) {
            q_title = "Ek Soru Açıklaması";
          }

          // await setDoc(
          //   doc(db, "exam_questions", eqId),
          //   {
          //     questions: {
          //       [question_name]: {
          //         ...qObject,
          //         order,
          //         title: q_title,
          //       },
          //     },
          //   },
          //   { merge: true }
          // );
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const downloadLogs = async (studentId, examId) => {
  let logDocId = examId + "_" + studentId;
  let logActionList = [];
  let examQuestionsObject = await getExamQuestionsByExamId(examId);
  let questions = examQuestionsObject.questions; //{fjaskfajsdş:{ title: "fafas", order:"fdsa"}}

  const logObject = await getDoc(doc(db, "logs", logDocId));

  if (logObject.data()) {
    let actions = logObject.data().actions;
    logActionList.push([
      "Kullanıcı Adı",
      "Soru Başlığı",
      "Soru Numarası",
      "Kaydedilen Cevap",
      "Tarih-Saat",
      "Hata Mesajı",
    ]);

    actions.forEach((action) => {
      const cellArray = [
        action.user_name?.toString(),
        questions[action.question_id].title ?? "",
        questions[action.question_id].order ?? "",
        action.new_answer?.toString(),
        createStringFromDate(action.action_date?.toDate()),
        action.error?.toString(),
      ];

      logActionList.push(cellArray);
    });

    console.log("log_data_list41 :>> ", logActionList);
    arrayToCsv(logActionList, "logs");
  } else {
    alert(
      "Log kayıtları bulunamadı. (Bir kullanıcı bir sınava en son cevap kaydı yaptıktan 40 gün sonra loglar silinir.)"
    );
  }
};

function arrayToCsv(data, filename) {
  let content = data
    .map(
      (row) =>
        row
          .map(String) // convert every value to String
          .map((v) => v.replaceAll('"', '""')) // escape double colons
          .map((v) => v.replaceAll("undefined", ""))
          .map((v) => `"${v}"`) // quote it
          .join(",") // comma-separated
    )
    .join("\r\n"); // rows starting on new lines

  var blob = new Blob([content], { type: "text/csv;charset=utf-8;" });
  var url = URL.createObjectURL(blob);

  // Create a link to download it
  var pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", filename);
  pom.click();

  alert("indi");
}

const addDataToPublicData = async () => {
  const examList = await fetchCreatedExamList("bDyy9Z8AkiZEijYb8lkbd0pldpr1");
  console.log("examList", examList);

  await setDoc(
    doc(db, "settings", "public_data"),
    { home_page_exams: examList },
    { merge: true }
  );
  alert("bitti");
};

export const downloadCorrectAnswerPercentages = async () => {
  /**
   bu method bizim yine sadece tozoş sınavlarında kullandığımız bir method. Katılımcıların soru puanlarına göre doğru yanıt verdiği
   soruların puanlarının toplanması ile toplam aldıkları puanları bulmamızı sağlıyor.
   */
  const exam_id = "if2023_sinif_10_zj8HkZZLeKsdnBabIq7P"; //exam_list
  const exam_questions = await getExamQuestionsByExamId(exam_id);
  const answer_forms = await getAnswerForms(exam_id);
  const { questions } = exam_questions;
  const counterList = {};
  Object.keys(questions).map((key) => {
    counterList[key] = {
      correctCount: 0,
      totalAnswers: 0,
      qCategory: questions[key].category,
      title: questions[key].title,
    };
  });

  console.log(questions);

  let formsWithTotalScores = [];

  for (let i = 0; i < answer_forms.length; i++) {
    let new_answer_form = {},
      id;
    let total_score = 0,
      total_score_additional = 0,
      total_score_additional2 = 0;
    if (answer_forms[i]?.result_type !== "disqualified") {
      for (const [key, value] of Object.entries(questions)) {
        let thisIsNotARealQuestion = !value.type;

        if (!thisIsNotARealQuestion) {
          if (value.name?.includes("_ek")) {
            const answer = answer_forms[i][key]?.response.trim();
            // const correct_answers = value.answer.split("&");
            // correct_answers.forEach((a) => {
            //   if (answer === a.trim()) {
            //     if (
            //       value.name.includes("ek1") ||
            //       value.name.includes("ek2") ||
            //       value.name.includes("ek3") ||
            //       value.name.includes("ek4") ||
            //       value.name.includes("ek5")
            //     )
            //       total_score_additional += value.question_score;
            //     else total_score_additional2 += value.question_score;
            //   }
            // });
            if (answer === value.answer) {
              total_score_additional += value.question_score;
            }
          } else {
            const answer = answer_forms[i][key]?.response.trim();
            // const correct_answers = value.answer.split("&");
            // correct_answers?.forEach((a) => {
            //   if (answer === a.trim()) {
            //     total_score += value.question_score;
            //   }
            // });
            // console.log('answer', answer)

            counterList[value.id].totalAnswers++;
            if (answer === value.answer) {
              counterList[value.id].correctCount++;
              total_score += value.question_score;
            }
          }
        }
      }
    }

    id = answer_forms[i].id;

    // let result_text = "";

    // const isThisTozosExam = false;//TODO: Buraya başka bir yöntem düşünmeliyiz.

    //     if (isThisTozosExam) {
    //       result_text = `Puanınız (İlk 10 Sorudan): ${total_score.toFixed(3)}
    // 1. Grup Ek Soru Puanınız: ${total_score_additional.toFixed(3)}
    // 2. Grup Ek Soru Puanınız: ${total_score_additional2.toFixed(3)}`;
    //     } else {
    //       let scoreText = `Puanınız: ${total_score.toFixed(3)}`;
    //       let rankText = `Sıralamanız: ${rank.toFixed(3)}`
    //       result_text = ""};

    new_answer_form = {
      ...answer_forms[i],
      total_score,
      total_score_additional,
      total_score_additional2,
      // result: result_text,
    };

    formsWithTotalScores.push(new_answer_form);
  }

  formsWithTotalScores = formsWithTotalScores.sort(
    (f1, f2) => f2.total_score - f1.total_score
  ); //TODO: Additional score'ları da hesaba katarak sıralamalı... TOZOŞ için.

  for (let i = 0; i < formsWithTotalScores.length; i++) {
    const new_answer_form = formsWithTotalScores[i];

    const id = new_answer_form.id;
    delete new_answer_form.id;
    new_answer_form.rank = i + 1;
    if (i > 0) {
      const previous_answer_form = formsWithTotalScores[i - 1];
      if (previous_answer_form.total_score === new_answer_form.total_score)
        new_answer_form.rank = previous_answer_form.rank;
    }
    // if (i % 10 === 0 || i === answer_forms.length - 1)
    //   progressCallback(i, answer_forms.length - 1);
  }
  const csvTitles = [
    "Soru Adı",
    "Soru Kategorisi",
    "Sınava Katılan",
    "Toplam Verilen Doğru Cevap",
  ];
  let csvData = [];
  let csvFormatted = [];

  csvFormatted.push(csvTitles);

  for (let i = 0; i < Object.keys(questions).length; i++) {
    csvData.push(
      counterList[Object.keys(questions)[i]].title
        ? counterList[Object.keys(questions)[i]].title
        : ""
    );
    csvData.push(
      counterList[Object.keys(questions)[i]].qCategory
        ? counterList[Object.keys(questions)[i]].qCategory
        : ""
    );
    csvData.push(counterList[Object.keys(questions)[i]].totalAnswers);
    csvData.push(counterList[Object.keys(questions)[i]].correctCount);

    csvFormatted.push(csvData);
    csvData = [];
  }

  arrayToCsv(csvFormatted);
};

export const uploadScreenshotToStorage = async (file, examId, userId) => {
  const storageRef = ref(
    storage,
    "screenshots/" + examId + "/" + userId + "/" + new Date().getTime() + ".png"
  );
  uploadString(storageRef, file.split(",")[1], "base64")
    .then((snapshot) => {
      console.log("Uploaded a data_url string!");
    })
    .catch((error) => {
      console.log("error", error);
      alert("Hata oluştu. Lütfen tekrar deneyin.");
    });
};
